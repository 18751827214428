import React, {useState} from "react";
import logo from '../assets/svgs/logo.svg';
import program from '../assets/svgs/program.svg';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import SinglePost from "./SinglePost/SinglePost";
import Home from "./pages/Home";
import Posts from "./pages/Posts";
import Menu from "./menu/Menu";
import Events from "./pages/Events";
import SingleEvent from "./SingleEvent/SingleEvent";
import About from "./pages/About";
import Program from "./pages/Program";

const Homepage = () => {

    return (
        <div className="mobilePadding relative h-full z-20">
            <BrowserRouter>
                <Menu />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/posts" element={<Posts />} />
                    <Route path="posts/:id" element={<SinglePost />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="events/:id" element={<SingleEvent />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/program" element={<Program />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Homepage;