import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useHref, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import './single-post.scss';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton
} from "react-share";
import defaultImage from '../../assets/images/default-post-image-des.jpg';

const SinglePost = (props) => {

    const {id} = useParams();
    const [post, setPost] = useState();
    const [relatedPosts, setRelatedPosts] = useState();
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');
    const history = useNavigate()

    useEffect(() => {
        document.body.style.overflow = "auto";
        fetchPosts();
    }, [id])
    useEffect(() => {
    }, [post])
    const fetchPosts = async () =>{
        window.scrollTo({top: 0});
        const response = await axios(`https://api.wind-fest.me/wp-json/wp/v2/posts/${id}?_embed`);
        setPost(response.data);
    }

    const getAuthors = post && post._embedded.author.map((author) => {
        return <span className="sans text-lg text-black no-underline" key={author.id} rel="author">{author.name}</span>
    })

    const published_at = post && moment(post.date).format('DD.MM.YYYY');

    const createMarkup = function (html) {
        return { __html: html };
    }

    const goTo = (postId) => {
        history(`/posts/${postId}`, true);
    }

    return (
        <div className="post">
            {
                post && (

                    <div className="container mx-auto ">
                        <article className="single-post">
                            <div className="grid grid-cols-12">
                                <div className="col-span-10 col-start-2 md:col-span-12 md:col-start-1 lg:col-span-10 lg:col-start-2">
                                    <h1 dangerouslySetInnerHTML={createMarkup(post.title.rendered)} className="text-3xl md:text-4xl lg:text-7xl thunder text-deepSea mb-6 lg:mb-10 lg:mt-20"></h1>
                                </div>
                                <div className="col-span-12 col-start-1">
                                    <hr className="hidden md:block text-deepSea"/>
                                </div>
                                <div className="mb-4 md:mb-0 col-span-10 col-start-2 md:col-span-12 md:col-start-1 lg:col-span-10 lg:col-start-2">
                                    <div className="flex flex-col md:flex-row items-start justify-between md:mt-6">
                                        <hr className="block md:hidden text-deepSea w-full"/>
                                        <span>
                                            <address className="mt-2 sans not-italic text-lg text-darkSea md:mt:6 mb-2">Autor: {getAuthors}</address>
                                            <time dateTime={published_at} className="mb-2 md:mb-0 block"><span className='sans not-italic text-lg text-darkSea'></span>{published_at}</time>
                                        </span>
                                        <hr className="block md:hidden text-deepSea w-full"/>
                                        <span>
                                            <div className="flex mt-4 mb-4 md:mt-2 md:mb-0">
                                               <FacebookShareButton url={`https://www.windfest.me/posts/${post.id}`} size={24} round="true">
                                                   <i className="lab text-2xl text-deepSea mr-4 ml-0 md:mr-0 md:ml-4 la-facebook"></i>
                                               </FacebookShareButton >
                                               <TwitterShareButton url={`https://www.windfest.me/posts/${post.id}`} size={24} round="true">
                                                   <i className="lab text-2xl text-deepSea mr-4 ml-0 md:mr-0 md:ml-4 la-twitter"></i>
                                               </TwitterShareButton>
                                               <LinkedinShareButton url={`https://www.windfest.me/posts/${post.id}`} size={24} round="true">
                                                   <i className="lab text-2xl text-deepSea mr-4 ml-0 md:mr-0 md:ml-4 la-linkedin"></i>
                                               </LinkedinShareButton>
                                               <EmailShareButton url={`https://www.windfest.me/posts/${post.id}`} size={24} round="true">
                                                   <i className="las text-2xl text-deepSea mr-4 ml-0 md:mr-0 md:ml-4 la-envelope"></i>
                                               </EmailShareButton>
                                            </div>
                                        </span>
                                        <hr className="block md:hidden text-deepSea w-full"/>
                                    </div>
                                </div>
                                <div className="col-span-12 col-start-1">
                                    {
                                        post._embedded['wp:featuredmedia'] && (
                                            <img className="featured md:mt-8 mb-0" src={post._embedded['wp:featuredmedia'][0].source_url} alt="" align={post.title.rendered}/>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                post && (
                                    <div className="lg:container mx-auto grid grid-cols-12">
                                        <div className="mt-8 lg:mt-14 mx-auto col-span-10 col-start-2 md:col-span-10 md:col-start-2 text-deepSea lg:col-start-3 lg:col-span-8" dangerouslySetInnerHTML={createMarkup(post.content.rendered)}></div>
                                    </div>
                                )
                            }
                        </article>
                    </div>
                )
            }
            <div className="bg-deepSea py-3">
                <div className="block w-full md:h-full md:justify-center">
                    <div className="flex flex-col items-center md:h-full md:justify-center md:flex-row items-center sans text-sm text-white md:my-0">
                        <span>© {(new Date().getFullYear())} Festival Vjetra sva prava zadržana.</span>
                        <div className="flex items-center">
                            <span className="ml-1">Made with</span>
                            <span className="flex">
                                <i className="paw-l las la-paw text-calmYellow text-xl"></i>
                                <i className="paw-r las la-paw text-calmYellow text-xl"></i>
                            </span>
                            <span className="block">by</span>
                            <a className="ml-1 mb-0 sans underline" href="https://happywolf.me">Happy Wolf</a>.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SinglePost;