import React, {useState} from "react";
import {Link} from "react-router-dom";

const ProgramDesktop = (props) => {

    const {
        program,
        setProgram,
        setAllEvents,
        setSport,
        setCulture,
        setFun,
        setEducation,
        allEvents,
        sport,
        culture,
        fun,
        education
    } = props;
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');
    const [showFilter, setShowFilter] = useState(false);

    const createMarkup = function (html) {
        return {__html: html};
    }

    return (
        <>
            <div className="filter_posts filter_posts--program filter-post--zindex h-100 bg-calmYellow" style={{ transform: window.innerWidth < 1025 ? `translateX(${showFilter ? '0': '-100'}%`: ''}}>
                <div className="ml-12 pr-2 w-64 relative">
                    <div className="mb-3 mt-3 flex items-center">
                        <i className="hidden lg:inline-block text-deepSea las la-sliders-h text-2xl"></i>
                        <span onClick={() => setShowFilter(false)}>
                            <i className="lg:hidden las la-times text-calmRed text-2xl"></i>
                        </span>
                        <span className="sans font-normal text-base text-deepSea ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                    </div>
                    <hr className="text-deepSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Svi događaji': 'All events'}
                            <input type="checkbox" checked={allEvents} disabled={allEvents} onChange={() => setAllEvents(!allEvents)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap text-deepSea">{language === 'me' ? '5 vikenda, 16 događaja, 10 lokacija': '5 weekends, 16 events, 10 locations'}</span>
                    </div>
                    <hr className="text-deepSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">Sport
                            <input type="checkbox" checked={sport} onChange={() => setSport(!sport)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap text-deepSea">{language === 'me' ? '4 regate, 1 bike trka': '4 regattas, 1 bike race'}</span>
                    </div>
                    <hr className="text-deepSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Zabava' : 'Entertainment'}
                            <input type="checkbox" checked={fun} onChange={() => setFun(!fun)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap text-deepSea">{language === 'me' ? 'Od Partibrejkersa do Random' : 'From the Partibrejkers to Random'}</span>
                    </div>
                    <hr className="text-deepSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Kultura' : 'Culture'}
                            <input type="checkbox" checked={culture} onChange={() => setCulture(!culture)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap text-deepSea">{language === 'me' ? 'Pozorište, film, klasična muzika' : 'Theatre, film, classical music'}</span>
                    </div>
                    <hr className="text-deepSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Edukacija' : 'Education'}
                            <input type="checkbox" checked={education} onChange={() => setEducation(!education)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap text-deepSea">{language === 'me' ? 'Konferencije, predavanja, tribine' : 'Conferences, lectures, panels'}</span>
                    </div>
                </div>
            </div>

            <div className="h-full md:mt-28 lg:mt-28 2xl:mt-36 z-30 relative">
                {
                    showFilter && <div onClick={() => setShowFilter(false)} className="overlay"></div>
                }
                <div>
                    <h1 className="thunder font-bold text-h1 mr-12 text-calmRed ml-5 lg:ml-12 mt-0 xl:mt-16">PROGRAM <span
                        className="text-calmYellow">2024</span></h1>
                    <span className="sans font-normal text-black md:text-xl lg:hidden xl:text-2xl ml-5">
                        {language === 'me' ? 'Prikaz programa po vikendima' : 'Presentation of the program by weekends'}
                    </span>
                </div>
                <div className="flex">
                    <div className="px-5 w-full program__max-width md:ml-0 lg:ml-80">
                        <hr className="text-separatorGray mt-4 lg:hidden"/>
                        <div className="flex justify-between mb-2 items-center pt-2">
                            <span className="sans md:hidden font-normal text-deepSea md:text-xl lg:block xl:text-2xl">
                                {language === 'me' ? 'Prikaz programa po vikendima kojima će se održavati.' : 'Presentation of the program by weekends that will be held.'}
                            </span>
                            <span className="lg:hidden" onClick={() => setShowFilter(true)}>
                                <i className="las la-sliders-h text-2xl"></i>
                                <span className="sans font-normal text-base text-calmRed ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                            </span>
                            {/*<div className="flex items-center">*/}
                            {/*    <span className="flex items-center">*/}
                            {/*        <i className="las la-list text-2xl"></i>*/}
                            {/*        <span className="ml-2">{language === 'me' ? 'prikaz liste' : 'list view'}</span>*/}
                            {/*    </span>*/}
                            {/*    <Link to={``}*/}
                            {/*          className="bg-calmYellow text-calmRed py-1 px-3 rounded sans fw-490 inline-flex items-center ml-10">*/}
                            {/*        <span*/}
                            {/*            className="text-calmRed sans text-base font-normal">{language === 'me' ? 'preuzmi program' : 'download program'}</span>*/}
                            {/*        <i className="las las la-download text-2xl ml-2"></i>*/}
                            {/*    </Link>*/}
                            {/*</div>*/}
                        </div>
                        <hr className="text-separatorGray"/>
                        <div className="program-container">
                            <div className="flex justify-between items-center mt-10">
                                <h2 className="thunder font-bold text-4xl"><span
                                    className="text-calmRed">{ language === 'me' ? 'VIKEND': 'WEEKEND'} 1</span><span
                                    className="text-calmYellow"> - 26 APRIL - 28 APRIL 2024</span></h2>
                                <div>
                                    {
                                        program['week1'] && program['week1']?.isOpen ?
                                            (
                                                <>
                            <span className="flex items-center cursor-pointer text-darkGray sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week1: {
                                          items: program['week1']?.items,
                                          isOpen: !program['week1']?.isOpen,
                                          length: program['week1']?.length,
                                          key: program['week1']?.key,
                                      }
                                  }))}>
                                <i className="las la-minus"></i>
                                <span className="ml-2">{language === 'me' ? 'zatvori' : 'close'}</span>
                            </span>
                                                </>
                                            ) : (
                                                <>
                            <span className="flex items-center cursor-pointer text-calmRed sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week1: {
                                          items: program['week1']?.items,
                                          isOpen: !program['week1']?.isOpen,
                                          length: program['week1']?.length,
                                          key: program['week1']?.key,
                                      }
                                  }))}>
                                <i className="las la-plus"></i>
                                <span className="ml-2">{language === 'me' ? 'otvori' : 'open'}</span>
                            </span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                program['week1'] && program['week1']?.isOpen && (
                                    <div className="w-full mt-4">
                                        <table className="customTable">
                                            <thead className="h-8">
                                            <tr className="h-8">
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">April 22
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">April 23
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">April 24
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">April 25
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">April 26</span> <span>{language === 'me'? 'Petak': 'Friday'}</span></th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">April 27</span> <span>{language === 'me'? 'Subota': 'Saturday'}</span></th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">April 28</span> <span>{language === 'me'? 'Nedelja': 'Sunday'}</span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                program && program['week1']?.items && (
                                                    program['week1']?.items[program['week1'].key]?.map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    <div>
                                                                        {
                                                                            program && program['week1'] && program['week1'].items['2024-04-26'] && program['week1'].items['2024-04-26'][index] && (
                                                                                <Link to={`/posts/${program['week1'].items['2024-04-26'][index].id}`} className="program-item">
                                                                                    <span
                                                                                          className="sans block text-sm font-bold program-item-title"
                                                                                          dangerouslySetInnerHTML={createMarkup(program['week1'].items['2024-04-26'][index].title.rendered)}
                                                                                    >
                                                                                    </span>
                                                                                    <span className="sans text-sm program-item-location">{program['week1'].items['2024-04-26'][index].lokacija} - <time>{program['week1'].items['2024-04-26'][index].vrijeme}</time></span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week1'] && program['week1'].items['2024-04-27'] && program['week1'].items['2024-04-27'][index] && (
                                                                            <Link to={`/posts/${program['week1'].items['2024-04-27'][index].id}`} className="hover:text-calmRed" className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week1'].items['2024-04-27'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week1'].items['2024-04-27'][index].lokacija} - <time>{program['week1'].items['2024-04-27'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week1'] && program['week1'].items['2024-04-28'] && program['week1'].items['2024-04-28'][index] && (
                                                                            <Link to={`/posts/${program['week1'].items['2024-04-28'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week1'].items['2024-04-28'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week1'].items['2024-04-28'][index].lokacija} - <time>{program['week1'].items['2024-04-28'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            <div className="flex justify-between items-center mt-10">
                                <h2 className="thunder font-bold text-4xl"><span
                                    className="text-calmRed">{ language === 'me' ? 'VIKEND': 'WEEKEND'} 2</span><span
                                    className="text-calmYellow"> - 3 {language === 'me' ? 'MAJ': 'MAY'} - 5 {language === 'me' ? 'MAJ': 'MAY'} 2024</span></h2>
                                <div>
                                    {
                                        program['week2'] && program['week2']?.isOpen ?
                                            (
                                                <>
                            <span className="flex items-center cursor-pointer text-darkGray sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week2: {
                                          items: program['week2']?.items,
                                          isOpen: !program['week2']?.isOpen,
                                          length: program['week2']?.length,
                                          key: program['week2']?.key,
                                      }
                                  }))}>
                                <i className="las la-minus"></i>
                                <span className="ml-2">{language === 'me' ? 'zatvori' : 'close'}</span>
                            </span>
                                                </>
                                            ) : (
                                                <>
                            <span className="flex items-center cursor-pointer text-calmRed sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week2: {
                                          items: program['week2']?.items,
                                          isOpen: !program['week2']?.isOpen,
                                          length: program['week2']?.length,
                                          key: program['week2']?.key,
                                      }
                                  }))}>
                                <i className="las la-plus"></i>
                                <span className="ml-2">{language === 'me' ? 'otvori' : 'open'}</span>
                            </span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                program['week2'] && program['week2']?.isOpen && (
                                    <div className="w-full mt-4">
                                        <table className="customTable">
                                            <thead className="h-8">
                                            <tr className="h-8">
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'April': 'April'} 29
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'April': 'April'} 30
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj': 'May'} 01
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj': 'May'} 02
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 03</span> <span>{language === 'me'? 'Petak': 'Friday'}</span></th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj': 'May'} 04
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj': 'May'} 05
                                                </th>
                                                {/*<th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 06</span> <span>{language === 'me'? 'Subota': 'Saturday'}</span></th>*/}
                                                {/*<th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 07</span> <span>{language === 'me'? 'Nedelja': 'Sunday'}</span></th>*/}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                program && program['week2'].items && program['week2'].items[program['week2'].key] && (
                                                    program['week2'].items[program['week2'].key].map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    <div>
                                                                        {
                                                                            program && program['week2'] && program['week2'].items['2024-05-03'] && program['week2'].items['2024-05-03'][index] && (
                                                                                <Link to={`/posts/${program['week2'].items['2024-05-03'][index].id}`} className="program-item">
                                                                                    <span
                                                                                          className="sans block text-sm font-bold program-item-title"
                                                                                          dangerouslySetInnerHTML={createMarkup(program['week2'].items['2024-05-03'][index].title.rendered)}
                                                                                    >
                                                                                    </span>
                                                                                    <span className="sans text-sm program-item-location">{program['week2'].items['2024-05-03'][index].lokacija} - <time>{program['week2'].items['2024-05-03'][index].vrijeme}</time></span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            <div className="flex justify-between items-center mt-10">
                                <h2 className="thunder font-bold text-4xl"><span
                                    className="text-calmRed">{ language === 'me' ? 'VIKEND': 'WEEKEND'} 3</span><span
                                    className="text-calmYellow"> - 10 {language === 'me' ? 'MAJ': 'MAY'} - 12 {language === 'me' ? 'MAJ': 'MAY'} 2024</span></h2>
                                <div>
                                    {
                                        program['week3'] && program['week3']?.isOpen ?
                                            (
                                                <>
                            <span className="flex items-center cursor-pointer text-darkGray sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week3: {
                                          items: program['week3']?.items,
                                          isOpen: !program['week3']?.isOpen,
                                          length: program['week3']?.length,
                                          key: program['week3']?.key,
                                      }
                                  }))}>
                                <i className="las la-minus"></i>
                                <span className="ml-2">{language === 'me' ? 'zatvori' : 'close'}</span>
                            </span>
                                                </>
                                            ) : (
                                                <>
                            <span className="flex items-center cursor-pointer text-calmRed sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week3: {
                                          items: program['week3']?.items,
                                          isOpen: !program['week3']?.isOpen,
                                          length: program['week3']?.length,
                                          key: program['week3']?.key,
                                      }
                                  }))}>
                                <i className="las la-plus"></i>
                                <span className="ml-2">{language === 'me' ? 'otvori' : 'open'}</span>
                            </span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                program['week3'] && program['week3']?.isOpen && (
                                    <div className="w-full mt-4">
                                        <table className="customTable">
                                            <thead className="h-8">
                                            <tr className="h-8">
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 06
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 07
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 08
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 09
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 10</span> <span>{language === 'me'? 'Petak': 'Friday'}</span></th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 11</span> <span>{language === 'me'? 'Subota': 'Saturday'}</span></th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 12</span> <span>{language === 'me'? 'Nedelja': 'Sunday'}</span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                program && program['week3'].items && program['week3'].items[program['week3'].key] && (
                                                    program['week3'].items[program['week3'].key].map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    <div>
                                                                        {
                                                                            program && program['week3'] && program['week3'].items['2024-05-10'] && program['week3'].items['2024-05-10'][index] && (
                                                                                <Link to={`/posts/${program['week3'].items['2024-05-10'][index].id}`} className="program-item">
                                                                                    <span
                                                                                          className="sans block text-sm font-bold program-item-title"
                                                                                          dangerouslySetInnerHTML={createMarkup(program['week3'].items['2024-05-10'][index].title.rendered)}
                                                                                    >
                                                                                    </span>
                                                                                    <span className="sans text-sm program-item-location">{program['week3'].items['2024-05-10'][index].lokacija} - <time>{program['week3'].items['2024-05-10'][index].vrijeme}</time></span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week3'] && program['week3'].items['2024-05-11'] && program['week3'].items['2024-05-11'][index] && (
                                                                            <Link to={`/posts/${program['week3'].items['2024-05-11'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week3'].items['2024-05-11'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week3'].items['2024-05-11'][index].lokacija} - <time>{program['week3'].items['2024-05-11'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week3'] && program['week3'].items['2024-05-12'] && program['week3'].items['2024-05-12'][index] && (
                                                                            <Link to={`/posts/${program['week3'].items['2024-05-12'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week3'].items['2024-05-12'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week3'].items['2024-05-12'][index].lokacija} - <time>{program['week3'].items['2024-05-12'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            <div className="flex justify-between items-center mt-10">
                                <h2 className="thunder font-bold text-4xl"><span
                                    className="text-calmRed">{ language === 'me' ? 'VIKEND': 'WEEKEND'} 4</span><span
                                    className="text-calmYellow"> - 17 {language === 'me' ? 'MAJ': 'MAY'} - 19 {language === 'me' ? 'MAJ': 'MAY'} 2024</span></h2>
                                <div>
                                    {
                                        program['week4'] && program['week4']?.isOpen ?
                                            (
                                                <>
                            <span className="flex items-center cursor-pointer text-darkGray sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week4: {
                                          items: program['week4']?.items,
                                          isOpen: !program['week4']?.isOpen,
                                          length: program['week4']?.length,
                                          key: program['week4']?.key,
                                      }
                                  }))}>
                                <i className="las la-minus"></i>
                                <span className="ml-2">{language === 'me' ? 'zatvori' : 'close'}</span>
                            </span>
                                                </>
                                            ) : (
                                                <>
                            <span className="flex items-center cursor-pointer text-calmRed sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week4: {
                                          items: program['week4']?.items,
                                          isOpen: !program['week4']?.isOpen,
                                          length: program['week4']?.length,
                                          key: program['week4']?.key,
                                      }
                                  }))}>
                                <i className="las la-plus"></i>
                                <span className="ml-2">{language === 'me' ? 'otvori' : 'open'}</span>
                            </span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                program['week4'] && program['week4']?.isOpen && (
                                    <div className="w-full mt-4">
                                        <table className="customTable">
                                            <thead className="h-8">
                                            <tr className="h-8">
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 13
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 14
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 15
                                                </th>
                                                <th className="text-left sans font-normal text-sm">{language === 'me' ? 'Maj' : 'May'} 16</th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'}  17</span> <span>{language === 'me'? 'Petak': 'Friday'}</span>
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 18</span> <span>{language === 'me'? 'Subota': 'Subota'}</span></th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'} 19</span> <span>{language === 'me'? 'Nedelja': 'Sunday'}</span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                program && program['week4']?.items && program['week4']?.items[program['week4'].key] && (
                                                    program['week4']?.items[program['week4'].key].map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    <div>
                                                                        {
                                                                            program && program['week4'] && program['week4'].items['2024-05-17'] && program['week4'].items['2024-05-17'][index] && (
                                                                                <Link to={`/posts/${program['week4'].items['2024-05-17'][index].id}`} className="program-item">
                                                                                    <span
                                                                                          className="sans block text-sm font-bold program-item-title"
                                                                                          dangerouslySetInnerHTML={createMarkup(program['week4'].items['2024-05-17'][index].title.rendered)}
                                                                                    >
                                                                                    </span>
                                                                                    <span className="sans text-sm program-item-location">{program['week4'].items['2024-05-17'][index].lokacija} - <time>{program['week4'].items['2024-05-17'][index].vrijeme}</time></span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week4'] && program['week4'].items['2024-05-18'] && program['week4'].items['2024-05-18'][index] && (
                                                                            <Link to={`/posts/${program['week4'].items['2024-05-18'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week4'].items['2024-05-18'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week4'].items['2024-05-18'][index].lokacija} - <time>{program['week4'].items['2024-05-18'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week4'] && program['week4'].items['2024-05-19'] && program['week4'].items['2024-05-19'][index] && (
                                                                            <Link to={`/posts/${program['week4'].items['2024-05-19'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week4'].items['2024-05-19'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week4'].items['2024-05-19'][index].lokacija} - <time>{program['week4'].items['2024-05-19'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                            <div className="flex justify-between items-center mt-10">
                                <h2 className="thunder font-bold text-4xl"><span
                                    className="text-calmRed">{ language === 'me' ? 'VIKEND': 'WEEKEND'} 5</span><span
                                    className="text-calmYellow"> - 23 {language === 'me' ? 'MAJ': 'MAY'} - 26 {language === 'me' ? 'MAJ': 'MAY'} 2024</span></h2>
                                <div>
                                    {
                                        program['week5'] && program['week5']?.isOpen ?
                                            (
                                                <>
                            <span className="flex items-center cursor-pointer text-darkGray sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week5: {
                                          items: program['week5']?.items,
                                          isOpen: !program['week5']?.isOpen,
                                          length: program['week5']?.length,
                                          key: program['week5']?.key,
                                      }
                                  }))}>
                                <i className="las la-minus"></i>
                                <span className="ml-2">{language === 'me' ? 'zatvori' : 'close'}</span>
                            </span>
                                                </>
                                            ) : (
                                                <>
                            <span className="flex items-center cursor-pointer text-calmRed sans text-base"
                                  onClick={() => setProgram(prevState => ({
                                      ...prevState,
                                      week5: {
                                          items: program['week5']?.items,
                                          isOpen: !program['week5']?.isOpen,
                                          length: program['week5']?.length,
                                          key: program['week5']?.key,
                                      }
                                  }))}>
                                <i className="las la-plus"></i>
                                <span className="ml-2">{language === 'me' ? 'otvori' : 'open'}</span>
                            </span>
                                                </>
                                            )
                                    }
                                </div>
                            </div>
                            {
                                program['week5'] && program['week5']?.isOpen && (
                                    <div className="w-full pb-8 mt-4">
                                        <table className="customTable">
                                            <thead className="h-8">
                                            <tr className="h-8">
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 20
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 21
                                                </th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 22
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'}  23</span> <span>{language === 'me'? 'Četvrta': 'Thursday'}</span></th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 24
                                                </th>
                                                <th className="text-left sans font-normal text-sm"><span className="text-calmRed font-bold">{language === 'me' ? 'Maj' : 'May'}  25</span> <span>{language === 'me'? 'Subota': 'Saturday'}</span></th>
                                                <th className="hidden xl:table-cell sans font-normal text-sm text-left">{language === 'me' ? 'Maj' : 'May'} 26</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                program && program['week5'].items && program['week5'].items[program['week5'].key] && (
                                                    program['week5'].items[program['week5'].key].map((item, index) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    <div>
                                                                        {
                                                                            program && program['week5'] && program['week5'].items['2024-05-23'] && program['week5'].items['2024-05-23'][index] && (
                                                                                <Link to={`/posts/${program['week5'].items['2024-05-23'][index].id}`} className="program-item">
                                                                                    <span
                                                                                          className="sans block text-sm font-bold program-item-title"
                                                                                          dangerouslySetInnerHTML={createMarkup(program['week5'].items['2024-05-23'][index].title.rendered)}
                                                                                    >
                                                                                    </span>
                                                                                    <span className="sans text-sm program-item-location">{program['week5'].items['2024-05-23'][index].lokacija} - <time>{program['week5'].items['2024-05-23'][index].vrijeme}</time></span>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                                <td className="w-1/5 align-top">
                                                                    {
                                                                        program && program['week5'] && program['week5'].items['2024-05-25'] && program['week5'].items['2024-05-25'][index] && (
                                                                            <Link to={`/posts/${program['week5'].items['2024-05-25'][index].id}`} className="program-item">
                                                                                <span
                                                                                      className="sans block text-sm font-bold program-item-title"
                                                                                      dangerouslySetInnerHTML={createMarkup(program['week5'].items['2024-05-25'][index].title.rendered)}
                                                                                >
                                                                                </span>
                                                                                <span className="sans text-sm program-item-location">{program['week5'].items['2024-05-25'][index].lokacija} - <time>{program['week5'].items['2024-05-25'][index].vrijeme}</time></span>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td className="hidden xl:table-cell w-10"></td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProgramDesktop;