import React, {useEffect, useState} from "react";
import './menu.scss';
import {Link} from "react-router-dom";
import logo from "../../assets/svgs/logo.svg";
import logoWhite from "../../assets/svgs/logo-white.svg";
import program from "../../assets/svgs/program.svg";
import {useLocation} from "react-router-dom";

const Menu = () => {

    const [showMenu, setShowMenu] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language'): 'me');
    const [bgClass, setBgClass] = useState('text-deepSea bg-calmYellow');
    const location = useLocation();

    const onClick = () => {
        setShowMenu(true);
    }

    useEffect(() => {
        if(!localStorage.getItem('language')) {
            localStorage.setItem('language', 'me');
        }
    }, []);

    function updateLanguage(lang) {
        localStorage.setItem('language', lang);
        setLanguage(lang);
        setTimeout(function () {
            window.location.reload();
        })
    }

    return (
        <header id="header" className={`bg-calmYellow md:bg-transparent fixed md:relative header justify-between items-center z-20 ${location.pathname === '/about' ? 'md:bg-white' : ''}`}>
            <div className="block md:hidden flex justify-between w-full">
                <Link className="flex items-center" to={'/'}>
                    <img className="logo w-14" src={logo} alt="Wind fest"/>
                </Link>
                <div className="block md:hidden cursor-pointer" onClick={() => onClick()}>
                    <i className="las la-bars text-2xl text-deepSea"></i>
                </div>
            </div>
            <div className={`hidden md:flex items-center p-3 ${location.pathname !== '/' ? 'bg-calmYellow md:bg-transparent': 'md:bg-deepSea'}`}>
                {
                    (
                        <div className="relative hidden md:block">
                            <div className="relative z-10 hidden md:flex items-start jus">
                                <div className="md:pr-16 lg:pr-32">
                                    {
                                        location.pathname === '/' ?
                                            (
                                                <Link to={'/'}>
                                                    <img className="hidden md:inline-block logo" src={logoWhite} alt="Wind fest"/>
                                                </Link>
                                            ):(
                                                <Link to={'/'}>
                                                    <img className="hidden md:inline-block logo" src={logo} alt="Wind fest"/>
                                                </Link>
                                            )
                                    }
                                </div>
                                <nav className="flex flex-col pr-24">
                                    <Link className={`${location.pathname !== '/'? 'text-deepSea': 'text-white'}   sans text-lg uppercase`} to="/program">{language === 'me' ? 'Program' : 'Program'}</Link>
                                    <Link className={`${location.pathname !== '/'? 'text-deepSea': 'text-white'} menu-link sans text-lg uppercase`} to="/posts">{language === 'me' ? 'Novosti' : 'Posts'}</Link>
                                    <Link className={`${location.pathname !== '/'? 'text-deepSea': 'text-white'} menu-link sans text-lg uppercase`} menu-bg="about" to="/about">{language === 'me' ? 'Festival' : 'Festival'}</Link>
                                </nav>

                                <div className="flex items-center">
                                    <a className="mr-3" href="https://www.instagram.com/windfest.me/" target="_blank" aria-label={language === 'me' ? 'Instagram stranica': 'Instagram page'}>
                                        <i className={`la la-instagram ${location.pathname === '/' ? 'text-white' : 
                                        'text-deepSea'} text-2xl`}></i>
                                    </a>
                                    <a className="mr-3" href="https://www.facebook.com/windfest.me" target="_blank" aria-label={language === 'me' ? 'Facebook stranica': 'Facebook page'}>
                                        <i className={`lab la-facebook ${location.pathname === '/' ? 'text-white' : 
                                        'text-deepSea'} text-2xl`}></i>
                                    </a>
                                    <a className="mr-7" href="mailTo:info@windfest.me" aria-label={language === 'me' ? 'Email adresa': 'Email address'}>
                                        <i className={`la la-envelope ${location.pathname === '/' ? 'text-white' : 
                                        'text-deepSea'} text-2xl`}></i>
                                    </a>
                                    {
                                        language === 'me' ? (
                                            <span onClick={() => {updateLanguage('en')}}
                                              className={`text-sm sans cursor-pointer underline fw-400  ${location.pathname === '/' ? 'text-white' : 
                                        'text-deepSea'} text-center`}>EN</span>
                                        ) : (
                                            <span onClick={() => {updateLanguage('me');
                                            }} className={`text-sm sans cursor-pointer underline fw-400 ${location.pathname === '/' ? 'text-white' : 
                                        'text-deepSea'} text-center`}>ME</span>
                                        )
                                    }
                                </div>
                            </div>
                            {
                                (location.pathname === '/' || location.pathname === '/posts' ) && (
                                    <div className="program hidden md:flex relative items-center">
                                        {/*<div>*/}
                                        {/*    <Link to={``} className="hidden lg:inline-flex bg-calmYellow text-darkSea py-2 px-3 mr-8 rounded sans fw-490 items-center">*/}
                                        {/*        <span className="text-darkSea">{language === 'me' ? 'preuzmi program' : 'download program'}</span>*/}
                                        {/*        <i className="las las la-download text-2xl ml-2"></i>*/}
                                        {/*    </Link>*/}
                                        {/*</div>*/}
                                        <Link className="hidden md:inline-block animate" to="/program">
                                            <img src={program} alt="Wind fest"/>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <nav className={`${showMenu ? 'menu slide-in-left--menu' : 'menu'} ${bgClass}`}>
                <div className="flex flex-col slide-in-top">
                    <span className="menu__item">
                        <Link onClick={() => setShowMenu(false)} className="sans" to="/">{language === 'me' ? 'Početna' : 'Home'}</Link>
                    </span>
                    <span className="menu__item">
                        <Link onClick={() => setShowMenu(false)} className="sans" to="/program">{language === 'me' ? 'Program' : '\n' + 'Program'}</Link>
                    </span>
                    <span className="menu__item">
                        <Link onClick={() => setShowMenu(false)} className="sans" to="/posts">{language === 'me' ? 'Novosti' : 'Posts'}</Link>
                    </span>
                    <span className="menu__item">
                        <Link onClick={() => setShowMenu(false)} className="sans" menu-bg="about" to="/about">{language === 'me' ? 'Festival' : 'Festival'}</Link>
                    </span>
                    <div className="flex items-center">
                        <a className="flex items-center" href="https://www.facebook.com/windfest.me" target="_blank">
                            <i className="lab la-facebook mr-8 text-2xl"></i>
                        </a>
                        <a className="flex items-center" href="https://www.instagram.com/windfest.me/" target="_blank">
                            <i className="la la-instagram mr-8 text-2xl"></i>
                        </a>
                        <a className="flex items-center" href="mailTo:info@windfest.me">
                            <i className="la la-envelope mr-8 text-2xl"></i>
                        </a>
                        {
                            language === 'me' ? (
                                <span onClick={() => {updateLanguage('en')}} className="text-sm cursor-pointer sans underline fw-400 text-center flex items-center">EN</span>
                            ) : (
                                <span onClick={() => {
                                    updateLanguage('me');
                                }} className="text-sm cursor-pointer sans underline fw-400 text-center flex items-center">ME</span>
                            )
                        }
                    </div>
                </div>
                <span onClick={() => setShowMenu(false)} className="menu__close cursor-pointer">
                    <i className="la la-times text-3xl"></i>
                </span>
            </nav>
        </header>
    )
}

export default Menu;