import React, {useEffect, useState} from "react";
import aboutImage from '../../assets/images/about-des.jpg';
import aboutLogo from '../../assets/images/about-logo-des.jpg';
import eclipse1 from '../../assets/images/Ellipse 1.jpg';
import eclipse2 from '../../assets/images/Ellipse 2.jpg';
import eclipse3 from '../../assets/images/Ellipse 3.jpg';
import eclipse4 from '../../assets/images/Ellipse 4.jpg';
import eclipse5 from '../../assets/images/Ellipse 5.jpg';
import eclipse6 from '../../assets/images/Ellipse 6.jpg';
import eclipse7 from '../../assets/images/Ellipse 7.jpg';
import eclipse8 from '../../assets/images/matija-grabic.jpg';
import {Helmet} from "react-helmet";

const About = () => {

    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');

    useEffect(() => {
        document.body.style.overflow = "auto";
    },[]);

    return (
        <div className="ml-0 mt-10 md:mt-0 md:ml-16 p-0 md:pb-14 lg:pb-40">
            <div className="container mx-auto md:mt-14">
                <div className="grid grid-cols-12">
                    <h1 className="md:ml-4 lg:ml-0 text-3xl md:text-5xl lg:text-8xl thunder text-calmRed mb-6 md:mb-14 lg:mb-20 md:mt-16 lg:mt-28 col-start-2 col-span-10 md:col-start-1 lg:col-start-2 lg:col-span-8 uppercase">{language === 'me' ? 'TREĆI FESTIVAL VJETRA, LJUBAVI I SREĆE' : 'THIRD FESTIVAL OF WIND, LOVE AND HAPPINESS' }</h1>
                    <p className="lg:col-start-2 lg:col-span-5 col-start-2 col-span-10 text-lg sans text-deepSea mb-6 lg:mb-6">
                        {
                            language === 'me' ? 'Tivatska regata po devetnaesti put, posljednje nedjelje u aprilu, označava početak nautičke sezone u Boki Kotoroskoj.' : 'The Tivat regatta for the nineteenth time, on the last Sunday in April, marks the beginning of the nautical season in Boka Bay.'
                        }
                    </p>
                    <p className="lg:col-start-2 lg:col-span-5 col-start-2 col-span-10 text-lg sans text-deepSea mb-6 lg:mb-0">
                        {
                            language === 'me' ? 'Istog vikenda počinje Festival vjetra 2024, koji će okupiti Tivćane i goste u energetskom plesu i kroz sport, zabavu, kulturu i edukaciju, I tokom pet vikenda - od 26.aprila do 25.maja- naš grad još jednom učiniti regionalnim epicentrom kvalitetne ponude za sve generacije.' : 'On the same weekend, the Wind Festival 2024 begins, which will bring together the people of Tivat and guests in energy dance and through sports, entertainment, culture and education, and during five weekends - from April 26 to May 25 - once again make our city the regional epicenter of quality offers for all generations.'
                        }
                    </p>
                </div>
            </div>
            <img className="mt-8 mb-8 md:mb-14 lg:mb-28 w-full md:mt-14 lg:mt-28" src={aboutImage} alt="Windfest ilustration"/>
            <div className="container mx-auto mb-6">
                <div className="grid grid-cols-12">
                    <p className="text-lg sans text-deepSea col-start-2 col-span-10 lg:col-start-2 lg:col-span-5 mb-6 lg:mb-0">
                        {
                            language === 'me' ? 'Održan 2022. godine po prvi put, Festival vjetra je ukazao na stare, a principe kojih smo se morali prisjetiti- samo zajedničkim snagama možemo ploviti u smjeru održivog razvoja zajednice, na korist svih nas. Biti svjesni i ponosni na ono što jesmo i ljepotu koju baštinimo i na najljepši je mogući način slaviti sa svijetom.' : 'Held for the first time in 2022, the Wind Festival pointed to the old principles that we had to remember - only with joint efforts can we sail in the direction of sustainable development of the community, to the benefit of all of us. To be aware and proud of who we are and the beauty we inherit and to celebrate it with the world in the most beautiful way possible.'
                        }
                    </p>
                    <p className="text-lg sans text-deepSea col-start-2 col-span-10 lg:col-start-8 lg:col-span-5">
                        {
                            language === 'me' ? 'Stoga festival i ove godine uvezuje tivatske institucije, organizacije, obrazovne ustanove, kulturne i sportske djelatnike, koji će zajedničkim snagama naš grad učiniti mjestom susreta, druženja, sportskih nadmetanja, edukativnih programa koji naglašavaju vrijednost ambijenta i nužnost njegovog očuvanja i adekvatne valorizacije.' : 'Therefore, this year the festival unites Tivat institutions, organizations, educational institutions, cultural and sports workers, who will make our city a place of meetings, socializing, sports competitions, educational programs that emphasize the value of the environment and the necessity of its preservation and adequate valorization.'
                        }
                    </p>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="grid grid-cols-12">
                    <p className="text-lg sans text-deepSea col-start-2 col-span-10 lg:col-start-2 lg:col-span-5 mb-6 lg:mb-0">
                        {
                            language === 'me' ? 'Jedrimo zajednički u još jedan poduhvat- Wind Fest 2024. Nastavljamo da širimo krila i da hvatamo dobre vjetrove današnjice, u čemu nam se ove godine pridružuje i veliki broj internacionalnih eksperata, sportista, muzičkih zvijezda, pozorišnih, audiovizuelnih umjetnika.' : 'We are sailing together in another venture - Wind Fest 2024. We continue to spread our wings and catch the good winds of today, in which this year we are joined by a large number of international experts, athletes, music stars, theater and audiovisual artists.'
                        }
                    </p>
                    <p className="text-lg sans text-deepSea col-start-2 col-span-10 lg:col-start-8 lg:col-span-5">
                        {
                            language === 'me' ? 'Maj 2024 je u Tivtu mjesec dobrih vjetrova, koji će se u godinama koje dolaze, vjerujemo, proširiti na cijelu Boku. Ambijent kojim se ponosimo gradili su jedrenjaci, nastavljamo tu plovidbu stvarajući nove vrijednosti.' : 'May 2024 is the month of good winds in Tivat, which we believe will spread to the entire Boka Bay in the years to come. The atmosphere we are proud of was built by sailing ships, we continue that sailing creating new values.'
                        }
                    </p>
                </div>
            </div>
            <div className="container mx-auto">
                <div className="grid grid-cols-12">
                    <h2 className="mt-7 md:ml-4 lg:ml-0 text-3xl md:text-5xl lg:text-8xl thunder text-calmRed md:mt-16 lg:mt-28 col-start-2 col-span-10 md:col-start-1 lg:col-start-2 lg:col-span-8 uppercase">{ language === 'me' ? 'treći festival vjetra otvara turističku sezonu' : 'the third wind festival opens the tourist season' }</h2>
                </div>
            </div>
            <img className="mt-8 mb-8 w-full md:mt-14 lg:mt-28 md:mb-14 lg:mb-28" src={aboutLogo} alt="Windfest logo"/>
            <div className="container mx-auto">
                <h3 className="ml-6 text-calmRed text-3xl md:text-4xl lg:text-7xl thunder col-start-12 mb-12 lg:mb-20">{language === 'me' ? 'Tim' : 'Team'}</h3>
            </div>
            <div className="container mx-auto grid grid-cols-12 gap-0 md:gap-8 lg:gap-6">
                <div className="mb-10 col-start-2 col-span-10 md:col-start-2 md:col-span-3 lg:col-start-1 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse5} alt="Vesna Vukić"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Vesna Vukić</b></span>
                    <span className="block sans text-deepSea text-base">{ language === 'me' ? 'Direktorica festivala' : 'Director of the festival'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: vesna@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://www.instagram.com/fibrelion_42" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-5 lg:col-start-4 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-5" src={eclipse2} alt="Vuk Bojanić"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Vuk Bojanić</b></span>
                    <span className="block sans text-deepSea text-base">{ language === 'me' ? 'Umjetnički direktor' : 'Art director'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: vuk@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://www.instagram.com/vukbojanic" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-8 lg:col-start-7 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse3} alt="Ivan Lučić"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Ivan Lučić</b></span>
                    <span className="block sans text-deepSea text-base">Marketing direktor</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: ivan@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://instagram.com/ivek_za_uvek" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-2 md:col-span-3 lg:col-start-10 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse4} alt="Antonela Stjepčević"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Antonela Stjepčević</b></span>
                    <span className="block sans text-deepSea text-base">{language === 'me' ? 'Urednica programa' : 'Program editor'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: antonela@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://instagram.com/antonelarajcevic" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-5 md:col-span-3 lg:col-start-1 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse1} alt="dr Frano Tripović"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>dr Frano Tripović</b></span>
                    <span className="block sans text-deepSea text-base">{ language === 'me' ? 'Idejni tvorac i producent' : 'Concept creator and producer'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: frano@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://www.instagram.com/avitarpuse/" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-8 lg:col-start-4 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse6} alt="Bojana Moškov"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Bojana Moškov</b></span>
                    <span className="block sans text-deepSea text-base">{ language === 'me' ? 'PR & Media koordinatorka' : 'PR & Media Coordinator'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: bojana@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="https://www.instagram.com/bojana.mo" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-2 lg:col-start-7 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6" src={eclipse7} alt="Anton Luković"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Anton Luković</b></span>
                    <span className="block sans text-deepSea text-base">{language === 'me' ? 'Video producent' : 'Video producer'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="mailTo: anton@windfest.me" aria-label={language === 'me' ? 'Email address': 'Email address'}>
                        <i className="las la-at text-2xl mr-4"></i>
                    </a>
                    <a href="" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
                <div className="mb-10 col-start-2 col-span-10 md:col-start-5 lg:col-start-10 md:col-span-3 lg:col-span-3 text-center">
                    <img className="mx-auto mb-6 profile-image" src={eclipse8} alt="Jana Radan"/>
                    <span className="block sans mb-2 text-calmRed text-lg lg:text-xl"><b>Matija Grabić</b></span>
                    <span className="block sans text-deepSea text-base">{language === 'me' ? 'Producent' : 'Producer'}</span>
                    <span className="block sans mb-4 text-deepSea text-base">{language === 'me' ? 'Suosnivač' : 'Co-founder'}</span>
                    <a href="https://www.instagram.com/tjibarg/" target="_blank" aria-label={language === 'me' ? 'Instagram profil': 'Instagram address'}>
                        <i className="lab la-instagram text-2xl"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default About;