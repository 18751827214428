import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams, useSearchParams} from "react-router-dom";
import moment from "moment";
import '../SinglePost/single-post.scss';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton
} from "react-share";

const SingleEvent = (props) => {

    const {id} = useParams();
    const [event, setEvent] = useState();
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');

    useEffect(() => {
        fetchPosts();
    }, [])
    useEffect(() => {
    }, [event])
    const fetchPosts=async()=>{
        const response = await axios(`https://api.wind-fest.me/wp-json/wp/v2/event_listing/${id}?_embed`);
        setEvent(response.data);
    }

    const published_at = event && moment(event.date).format('DD.MM.YYYY');

    const createMarkup = function (html) {
        return { __html: html };
    }

    return (
        <>
            {
                event && (
                    <div className="container mx-auto ml-0 md:ml-16 pb-1">
                        <article className="single-post">
                              <div className="grid grid-cols-12">
                                  <div className="col-span-10 col-start-2 md:col-span-9 md:col-start-2">
                                      <h1 className="text-3xl md:text-4xl lg:text-6xl libre text-deepSea mb-6 lg:mb-6" dangerouslySetInnerHTML={createMarkup(event.title.rendered)}></h1>
                                      <time><span className='sans not-italic text-lg text-deepSea mb-4'>{language === 'me' ? 'Objavljeno': 'Published'}: </span><b>{published_at}</b></time>
                                      {
                                          event.featured_media !== 0 && (
                                              <img className="featured-image mt-8 lg:mt-14" src={event._embedded['wp:featuredmedia'][0].source_url} alt=""/>
                                          )
                                      }
                                  </div>
                                  <div className="hidden share-icons lg:flex lg:flex-col">
                                      <FacebookShareButton url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                          <i className="lab text-2xl la-facebook"></i>
                                      </FacebookShareButton >
                                      <TwitterShareButton url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                          <i className="lab text-2xl la-twitter"></i>
                                      </TwitterShareButton>
                                      <LinkedinShareButton url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                          <i className="lab text-2xl la-linkedin"></i>
                                      </LinkedinShareButton>
                                      <EmailShareButton url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                          <i className="las text-2xl la-envelope"></i>
                                      </EmailShareButton>
                                  </div>
                              </div>
                              {
                                  event && (
                                      <div className="lg:container mx-auto grid grid-cols-12">
                                          <div className="mt-8 lg:mt-14 mx-auto col-span-10 col-start-2 md:col-span-8 md:col-start-3 lg:col-start-4 lg:col-span-6" dangerouslySetInnerHTML={createMarkup(event.content.rendered)}></div>
                                          <div className="relative share-icons lg:hidden lg:fixed lg:flex-col col-span-10 col-start-2 md:col-span-8 md:col-start-3 lg:col-start-4 lg:col-span-6">
                                              <FacebookShareButton quote={event.title.rendered} url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                                  <i className="lab text-2xl mr-2 lg:mr-0 la-facebook"></i>
                                              </FacebookShareButton >
                                              <TwitterShareButton title={event.title.rendered} url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                                  <i className="lab text-2xl mr-2 lg:mr-0 la-twitter"></i>
                                              </TwitterShareButton>
                                              <LinkedinShareButton title={event.title.rendered} url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                                  <i className="lab text-2xl mr-2 lg:mr-0 la-linkedin"></i>
                                              </LinkedinShareButton>
                                              <EmailShareButton subject={event.title.rendered} url={`https://www.windfest.me/events/${event.id}`} size={24} round="true">
                                                  <i className="las text-2xl mr-2 lg:mr-0 la-envelope"></i>
                                              </EmailShareButton>
                                          </div>
                                      </div>
                                  )
                              }
                        </article>
                    </div>
                )
            }
        </>

    )
}

export default SingleEvent;