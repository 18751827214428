import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import './post.scss';
import {Link, useLocation, useSearchParams} from "react-router-dom";
import {LocomotiveScrollProvider, useLocomotiveScroll} from "react-locomotive-scroll";
import defaultImage from "../../assets/images/default-post-image-des.jpg";


const Posts = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    if (!searchParams.get('page')) {
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    }

    const firstUpdate = useRef(true);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')));

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [categoryString, setCategoryString] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');
    const { scroll } = useLocomotiveScroll()
    const { pathname } = useLocation() // With react-router
    const containerRef = useRef(null)
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [categoriesUrl, setCategoriesUrl] = useState('');
    const [allEvents, setAllEvents] = useState(false);
    const [sport, setSport] = useState(searchParams.get('sport') === '1');
    const [culture, setCulture] = useState(searchParams.get('culture') === '1');
    const [fun, setFun] = useState(searchParams.get('fun') === '1');
    const [education, setEducation] = useState(searchParams.get('education') === '1');


    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        fetchPosts();
    }, [categoriesUrl, currentPage]);

    useEffect(() => {
        generateURL();
    }, [sport,culture,fun,education])


    useEffect(() => {
        if (allEvents) {
            setSport(false);
            setCulture(false);
            setFun(false);
            setEducation(false);
        }
    }, [allEvents])
    const fetchPosts = async () => {
        document.body.style.overflow = "auto";
        setIsLoading(true);

        let url = `https://api.wind-fest.me/wp-json/wp/v2/posts?_embed&per_page=8&page=${currentPage}&tags=${language === 'me' ? 47 : 48}${categoriesUrl}`;

        const response = await axios(url);
        setPosts(response.data);
        setIsLoading(false);

        if (!sport && !culture && !fun && !education) {
            setAllEvents(true);
        }
        scroll &&
        scroll.scrollTo(document.getElementById('start'), {
            left: 0,
            top: 0
        });
        setShowFilter(false);
        window.scrollTo(containerRef);
        setTimeout(function () {
            setTotalNumberOfPages(Array.from(Array(parseInt(response.headers['x-wp-totalpages'])).keys(), (_, i) => i + 1));
        })
    }

    const generateURL = () => {

        searchParams.delete('sport');
        searchParams.delete('culture');
        searchParams.delete('fun');
        searchParams.delete('education');

        let url = '';

        if (sport) {
            url += '43 ';
        }

        if (fun) {
            url += '45 ';
        }

        if (culture) {
            url += '44 ';
        }

        if (education) {
            url += '46';
        }

        if (url.length === 11 || url.length === 0) {
            setAllEvents(true);
            setSport(false);
            setCulture(false);
            setFun(false);
            setEducation(false);
            url = '&categories=43 44 45 46';
        } else {
            setAllEvents(false);
            url = '&categories=' + url;
        }
        setCategoriesUrl(url);
    }

    function goToPreviousPage() {
        setCurrentPage((currentPage - 1));
        searchParams.set('page', currentPage - 1);
        setSearchParams(searchParams);
    }

    function goToNextPage() {
        setCurrentPage(currentPage + 1);
        searchParams.set('page', currentPage + 1);
        setSearchParams(searchParams);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(parseInt(pageNumber));
        searchParams.set('page', pageNumber);
        setSearchParams(searchParams);
        setTimeout(function () {
            setIsImageLoaded(true);
        }, 1000);
    }

    const createMarkup = function (html) {
        return { __html: html };
    }

    return (
        <div className="md:mt-24 lg:mt-0">
            {
                showFilter && <div onClick={() => setShowFilter(false)} className="overlay hidden md:block"></div>
            }
            {
                window.innerWidth < 1025 ? (
                    <div className="md:mx-8">
                        <div className="flex justify-between items-baseline px-8 pt-8 pb-4 lg:p-0 lg:inline-block">
                            <h1 className="md:hidden title-posts">{ language === 'me' ? 'NOVOSTI': 'POSTS' }</h1>
                            <div>
                                <span className={`${currentPage === 1 ? 'page-number--one':'page-number--other'} page-number ${language === 'en' ? 'en': ''}`}>{currentPage}</span>
                                <span className={`${currentPage === 1 ? 'page--one': 'page--other'} page ${language === 'en' ? 'en': ''}`}>{language === 'me' ? 'stranica' : 'page'}</span>
                            </div>
                        </div>
                        <hr className="lg:hidden text-separatorGray"/>
                        <div className="lg:hidden px-8 md:px-0 lg:px-8">
                            <div className="mb-3 mt-3 flex items-center">
                                <span onClick={() => setShowFilter(true)}>
                                    <i className="las la-sliders-h text-2xl"></i>
                                    <span className="sans font-normal text-base text-darkSea ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                                </span>
                            </div>
                        </div>
                        <hr className="lg:hidden text-separatorGray"/>
                        <h1 className="hidden md:block lg:hidden title-posts mt-10">{ language === 'me' ? 'NOVOSTI': 'POSTS' }</h1>
                    </div>
                ): ''
            }
            <div className="filter_posts h-100 bg-calmYellow" style={{ transform: window.innerWidth < 1025 ? `translateX(${showFilter ? '0': '-100'}%`: ''}}>
                <div className="ml-12 pr-2 w-64 relative">
                    <div className="mb-3 mt-3 flex items-center">
                        <i className="hidden lg:inline-block las la-sliders-h text-2xl"></i>
                        <span onClick={() => setShowFilter(false)}>
                            <i className="lg:hidden las la-times text-2xl"></i>
                        </span>
                        <span className="sans font-normal text-base text-darkSea ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Svi događaji': 'All events'}
                            <input type="checkbox" checked={allEvents} disabled={allEvents} onChange={() =>{ setAllEvents(!allEvents) }}/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? '5 vikenda, 16 događaja, 10 lokacija': '5 weekends, 16 events, 10 locations'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">Sport
                            <input type="checkbox" checked={sport} onChange={() => { setSport(!sport) } }/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? '4 regate, 1 bike trka': '4 regattas, 1 bike race'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Zabava' : 'Entertainment'}
                            <input type="checkbox" checked={fun} onChange={() => { setFun(!fun) }}/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Od Partibrejkersa do Randoma' : 'From the Partibrejkers to Random'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Kultura' : 'Culture'}
                            <input type="checkbox" checked={culture} onChange={() => {  setCulture(!culture) }}/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Pozorište, film, klasična muzika' : 'Theatre, film, classical music'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Edukacija' : 'Education'}
                            <input type="checkbox" checked={education} onChange={() => { setEducation(!education) }}/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Konferencije, predavanja, tribine' : 'Conferences, lectures, panels'}</span>
                    </div>
                </div>
            </div>
            <LocomotiveScrollProvider
                options={
                    {
                        smooth: true,
                        direction: 'horizontal',
                        reloadOnContextChange: true,
                        repeat: true,
                        initPosition: { x: 0, y: 0 },
                        smartphone: {
                            direction: 'vertical'
                        },
                        mobile: {
                            smooth: true,
                            direction: 'vertical',
                            reloadOnContextChange: true
                        },
                        tablet: {
                            smooth: true,
                            direction: 'vertical',
                            gestureDirection: 'vertical',
                            reloadOnContextChange: true
                        }
                        // ... all available Locomotive Scroll instance options
                    }
                }
                watch={
                    [
                        posts, isImageLoaded, currentPage, isLoading, pathname
                        //..all the dependencies you want to watch to update the scroll.
                        //  Basicaly, you would want to watch page/location changes
                        //  For exemple, on Next.js you would want to watch properties like `router.asPath` (you may want to add more criterias if the instance should be update on locations with query parameters)
                    ]
                }
                onUpdate={(scroll) => {
                    if (scroll) {
                        scroll.scrollTo(document.getElementById('start'));
                    }
                }}
                scrollTo={'start'}
                onLocation={() => window.scrollTo(0, { duration: 0, disableLerp: true })}
                containerRef={containerRef}
            >
                <main className="flex flex-col lg:flex-row h-full w-full" data-scroll-container ref={containerRef}>
                    <div id="start" data-scroll-section className="pt-6 lg:pt-24 lg:pt-posts xl:pt-posts 2xl:pt-posts">
                        <div className="flex flex-col lg:flex-row h-full flex-wrap lg:flex-nowrap mx-8 lg:mx-12 lg:pl-max">
                            {
                                posts.map((post, i) => {
                                    return(
                                        <div
                                            key={post.id}
                                            className={`${posts.length !== i + 1 ?'mb-12 lg:mr-20 lg:mb-0 post__image': 'mb-6 lg:mb-0 post__image'} 
                                        ${i % 2 === 1 && window.innerWidth > 590 && window.innerWidth < 1025 ? 'self-end': ''}
                                        ${i === posts.length - 1 ? 'mb-0': ''}`}>
                                            <Link to={`/posts/${post.id}`} style={{height: "max-content"}}>
                                                <img className="post__image block w-full h-auto rounded-lg mb-6 object-cover object-center 100%"
                                                     onLoad={() => setIsImageLoaded(true)}
                                                     alt={post.title.rendered}
                                                     src={post.featured_media !== 0 ? post._embedded['wp:featuredmedia'][0].source_url : defaultImage} />
                                                <h1 className="thunder whitespace-pre-wrap text-calmRed text-4xl mb-4 leading-9" dangerouslySetInnerHTML={createMarkup(post.title.rendered)}></h1>
                                                <p className="post__description sans whitespace-pre-wrap text-deepSea mb-2" dangerouslySetInnerHTML={createMarkup(post.excerpt.rendered)}></p>
                                                <button className="bg-calmYellow text-darkSea py-2 px-3 rounded sans fw-490 inline-flex items-center">
                                                    <span className="text-darkSea">{language === 'me' ? 'pročitaj više' : 'read more'}</span>
                                                    <i className="las la-long-arrow-alt-right text-2xl ml-2"></i>
                                                </button>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    {
                        !isLoading && window.innerWidth > 1025 && (
                            <div data-scroll-section className="bg-deepSea z-20 relative">
                                <div className="block w-full md:h-full md:justify-center">
                                    <div className="flex flex-col items-center md:h-full md:justify-center md:flex-row md:flex-col-reverse items-center sans text-sm text-white px-3 my-2 md:my-0">
                                        <span className="rotated">© {(new Date().getFullYear())} Festival Vjetra sva prava zadržana.</span>
                                        <div className="flex items-center md:flex-col-reverse">
                                            <span className="rotated mb-1"> Made with</span>
                                            <span className="flex md:my-1">
                                            <i className="paw-l las la-paw text-calmYellow text-xl"></i>
                                            <i className="paw-r las la-paw text-calmYellow text-xl"></i>
                                        </span>
                                            <span className="rotated block">by</span>
                                            <a className="ml-1 mb-0 md:mb-1 md:ml-0 sans rotated underline" href="https://happywolf.me">Happy Wolf</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </main>
            </LocomotiveScrollProvider>
            {
                window.innerWidth < 1025 && (
                    <div className="my-16 lg:my-0 flex pagination items-center justify-center  text-deepSea">
                        {
                            totalNumberOfPages.map(page => {
                                return <button className={`${currentPage !== page ? 'opacity-50': ''} mx-4 text-darkSea cursor-pointer`} onClick={changePage} key={page}>{page}</button>
                            })
                        }
                    </div>
                )
            }
            <h1 className="hidden lg:inline-block title-posts">{ language === 'me' ? 'NOVOSTI': 'POSTS' }</h1>
            <h1 className="hidden lg:inline-block title-posts">{ language === 'me' ? 'NOVOSTI': 'POSTS' }</h1>
            <span className={`${currentPage === 1 ? 'page-number--one':'page-number--other'} hidden lg:inline-block  page-number ${language === 'en' ? 'en': ''}`}>{currentPage}</span>
            <span className={`${currentPage === 1 ? 'page--one': 'page--other'} hidden lg:inline-block  page ${language === 'en' ? 'en': ''}`}>{language === 'me' ? 'stranica' : 'page'}</span>
            {
                window.innerWidth > 1025 && (
                    <div className="my-16 lg:my-0 flex pagination items-center justify-center  text-deepSea">
                        {
                            totalNumberOfPages.map(page => {
                                return <button className={`${currentPage !== page ? 'opacity-50': ''} mx-4 text-darkSea cursor-pointer`} onClick={changePage} key={page}>{page}</button>
                            })
                        }
                    </div>
                )
            }
            {
                window.innerWidth < 1025 && (
                    <div className="bg-deepSea py-3">
                        <div className="block w-full md:h-full md:justify-center">
                            <div className="flex flex-col items-center md:h-full md:justify-center md:flex-row items-center sans text-sm text-white md:my-0">
                                <span>© {(new Date().getFullYear())} Festival Vjetra sva prava zadržana.</span>
                                <div className="flex items-center">
                                    <span className="ml-1">Made with</span>
                                    <span className="flex">
                                <i className="paw-l las la-paw text-calmYellow text-xl"></i>
                                <i className="paw-r las la-paw text-calmYellow text-xl"></i>
                            </span>
                                    <span className="block">by</span>
                                    <a className="ml-1 mb-0 sans underline" href="https://happywolf.me">Happy Wolf</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Posts;