import React, {useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import moment from "moment/moment";

const ProgramMobile = (props) => {

    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');
    const [showFilter, setShowFilter] = useState(false);

    const {
        program,
        setProgram,
        setAllEvents,
        setSport,
        setCulture,
        setFun,
        setEducation,
        allEvents,
        sport,
        culture,
        fun,
        education
    } = props;

    const createMarkup = function (html) {
        return { __html: html };
    }

    return (
        <div>
            {
                window.innerWidth < 1025 ? (
                    <div className="md:mx-8">
                        <div className="justify-between items-baseline px-8 pt-8 pb-4 lg:p-0 lg:inline-block">
                            <h1 className="md:hidden title-posts mb-4">{ language === 'me' ? 'PROGRAM': 'PROGRAM' } <span className="text-calmYellow">2024</span></h1>
                            <p className="text-deepSea text-xl mb-4 sans">{language === 'me' ? 'Prikaz programa po vikendima kojima će se održavati' : 'Presentation of the program by weekends that will be held'}.</p>
                            {/*<Link to={``} className="bg-calmYellow text-darkSea py-1 px-3 rounded sans fw-490 flex items-center justify-center w-full text-center">*/}
                            {/*    <span className="text-calmRed sans text-base font-normal">{language === 'me' ? 'preuzmi program' : 'download program'}</span>*/}
                            {/*    <i className="las las la-download text-2xl ml-2"></i>*/}
                            {/*</Link>*/}
                        </div>
                        <hr className="lg:hidden text-separatorGray"/>
                        <div className="lg:hidden px-8 md:px-0 lg:px-8">
                            <div className="mb-3 mt-3 flex items-center">
                                <span onClick={() => setShowFilter(true)}>
                                    <i className="las la-sliders-h text-2xl"></i>
                                    <span className="sans font-normal text-base text-darkSea ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                                </span>
                            </div>
                        </div>
                        <hr className="lg:hidden text-separatorGray"/>
                        <h1 className="hidden md:block lg:hidden title-posts mt-10">{ language === 'me' ? 'NOVOSTI': 'POSTS' }</h1>
                    </div>
                ): ''
            }
            <div className="filter_posts h-100 bg-calmYellow" style={{ transform: window.innerWidth < 1025 ? `translateX(${showFilter ? '0': '-100'}%`: ''}}>
                <div className="ml-12 pr-2 w-64 relative">
                    <div className="mb-3 mt-3 flex items-center">
                        <i className="hidden lg:inline-block las la-sliders-h text-2xl"></i>
                        <span onClick={() => setShowFilter(false)}>
                            <i className="lg:hidden las la-times text-2xl"></i>
                        </span>
                        <span className="sans font-normal text-base text-darkSea ml-2">{language === 'me' ? 'Filteri': 'Filters'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Svi događaji': 'All events'}
                            <input type="checkbox" checked={allEvents} disabled={allEvents} onChange={() => setAllEvents(!allEvents)}/>
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? '5 vikenda, 16 događaja, 10 lokacija': '5 weekends, 16 events, 10 locations'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">Sport
                            <input type="checkbox" checked={sport} onChange={() => setSport(!sport)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? '4 regate, 1 bike trka': '4 regattas, 1 bike race'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Zabava' : 'Entertainment'}
                            <input type="checkbox" checked={fun} onChange={() => setFun(!fun)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Od Partibrejkersa do Randoma' : 'From the Partibrejkers to Random'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Kultura' : 'Culture'}
                            <input type="checkbox" checked={culture} onChange={() => setCulture(!culture)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Pozorište, film, klasična muzika' : 'Theatre, film, classical music'}</span>
                    </div>
                    <hr className="text-darkSea"/>
                    <div className="my-3">
                        <label className="checkbox-container sans">{language === 'me' ? 'Edukacija' : 'Education'}
                            <input type="checkbox" type="checkbox" checked={education} onChange={() => setEducation(!education)} />
                            <span className="checkmark"></span>
                        </label>
                        <span className="whitespace-pre-wrap">{language === 'me' ? 'Konferencije, predavanja, tribine' : 'Conferences, lectures, panels'}</span>
                    </div>
                </div>
            </div>
            <div className="px-8">
                <div className="flex items-end justify-between pt-6">
                    <h2 className="thunder uppercase text-3xl font-bold"><span className="text-calmRed block">{language === 'me' ? 'Vikend': 'Weekend'} 1</span><span className="text-calmYellow">26 april - 28 april 2024</span></h2>
                    <span onClick={() => setProgram(prevState => ({
                        ...prevState,
                        week1: {
                            items: program['week1'].items,
                            isOpen: program['week1'] && !program['week1']?.isOpen
                        }
                    }))}>
                        {
                            program['week1']?.isOpen ? <i className="las la-minus-circle text-2xl text-calmRed"></i> : <i className="las la-plus-circle text-2xl text-calmRed"></i>
                        }
                    </span>
                </div>
                <hr className={`text-separatorGray ${program['week1']?.isOpen ? 'my-4' : 'mt-4'}`}/>
                {
                    program['week1'] && program['week1']?.isOpen && (
                        <>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-04-26" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-04-26').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-04-26').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week1']?.items['2024-04-26'] && program['week1']?.items['2024-04-26'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week1']?.items['2024-04-26'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-04-27" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-04-27').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-04-27').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week1']?.items['2024-04-27'] && program['week1']?.items['2024-04-27'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week1']?.items['2024-04-27'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-04-30" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-04-28').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-04-28').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week1']?.items['2024-04-28'] && program['week1']?.items['2024-04-28'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week1']?.items['2024-04-28'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray"/>
                        </>
                    )
                }
            </div>
            <div className="px-8">
                <div className="flex items-end justify-between pt-6">
                    <h2 className="thunder uppercase text-3xl font-bold"><span className="text-calmRed block">{language === 'me' ? 'Vikend': 'Weekend'} 2</span><span className="text-calmYellow">03 {language === 'me' ? 'maj': 'may'} - 05 {language === 'me' ? 'maj': 'may'} 2024</span></h2>
                    <span onClick={() => setProgram(prevState => ({
                        ...prevState,
                        week2: {
                            items: program['week2'].items,
                            isOpen: !program['week2']?.isOpen
                        }
                    }))}>
                        {
                            program['week2']?.isOpen ? <i className="las la-minus-circle text-2xl text-calmRed"></i> : <i className="las la-plus-circle text-2xl text-calmRed"></i>
                        }
                    </span>
                </div>
                <hr className={`text-separatorGray ${program['week2']?.isOpen ? 'my-4' : 'mt-4'}`}/>
                {
                    program['week2']?.isOpen && (
                        <>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-03" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-03').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-03').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week2']?.items['2024-05-03'] && program['week2']?.items['2024-05-03'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week2']?.items['2024-05-03'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray"/>
                        </>
                    )
                }
            </div>
            <div className="px-8">
                <div className="flex items-end justify-between pt-6">
                    <h2 className="thunder uppercase text-3xl font-bold"><span className="text-calmRed block">{language === 'me' ? 'Vikend': 'Weekend'} 3</span><span className="text-calmYellow">10 {language === 'me' ? 'maj': 'may'} - 12 {language === 'me' ? 'maj': 'may'} 2024</span></h2>
                    <span onClick={() => setProgram(prevState => ({
                        ...prevState,
                        week3: {
                            items: program['week3'].items,
                            isOpen: !program['week3']?.isOpen
                        }
                    }))}>
                        {
                            program['week3']?.isOpen ? <i className="las la-minus-circle text-2xl text-calmRed"></i> : <i className="las la-plus-circle text-2xl text-calmRed"></i>
                        }
                    </span>
                </div>
                <hr className={`text-separatorGray ${program['week3']?.isOpen ? 'my-4' : 'mt-4'}`}/>
                {
                    program['week3']?.isOpen && (
                        <>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-10" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-10').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-10').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week3']?.items['2024-05-10'] && program['week3']?.items['2024-05-10'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week3']?.items['2024-05-10'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-11" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-11').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-11').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week3']?.items['2024-05-11'] && program['week3']?.items['2024-05-11'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week3']?.items['2024-05-11'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-12" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-12').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-12').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week3']?.items['2024-05-12'] && program['week3']?.items['2024-05-12'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week3']?.items['2024-05-12'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray"/>
                        </>
                    )
                }
            </div>
            <div className="px-8">
                <div className="flex items-end justify-between pt-6">
                    <h2 className="thunder uppercase text-3xl font-bold"><span className="text-calmRed block">{language === 'me' ? 'Vikend': 'Weekend'} 4</span><span className="text-calmYellow">17 {language === 'me' ? 'maj': 'may'} - 19 {language === 'me' ? 'maj': 'may'} 2024</span></h2>
                    <span onClick={() => setProgram(prevState => ({
                        ...prevState,
                        week4: {
                            items: program['week4'].items,
                            isOpen: !program['week4']?.isOpen
                        }
                    }))}>
                        {
                            program['week4']?.isOpen ? <i className="las la-minus-circle text-2xl text-calmRed"></i> : <i className="las la-plus-circle text-2xl text-calmRed"></i>
                        }
                    </span>
                </div>
                <hr className={`text-separatorGray ${program['week4']?.isOpen ? 'my-4' : 'mt-4'}`}/>
                {
                    program['week4']?.isOpen && (
                        <>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-17" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-17').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-17').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week4']?.items['2024-05-17'] && program['week4']?.items['2024-05-17'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week4']?.items['2024-05-17'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-18" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-18').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-18').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week4']?.items['2024-05-18'] && program['week4']?.items['2024-05-18'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week4']?.items['2024-05-18'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-19" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-19').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-19').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week4']?.items['2024-05-19'] && program['week4']?.items['2024-05-19'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week4']?.items['2024-05-19'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray"/>
                        </>
                    )
                }
            </div>
            <div className="px-8">
                <div className="flex items-end justify-between pt-6">
                    <h2 className="thunder uppercase text-3xl font-bold"><span className="text-calmRed block">{language === 'me' ? 'Vikend': 'Weekend'} 5</span><span className="text-calmYellow">24 {language === 'me' ? 'maj': 'may'} - 26 {language === 'me' ? 'maj': 'may'} 2024</span></h2>
                    <span onClick={() => setProgram(prevState => ({
                        ...prevState,
                        week5: {
                            items: program['week5'].items,
                            isOpen: !program['week5']?.isOpen
                        }
                    }))}>
                        {
                            program['week5']?.isOpen ? <i className="las la-minus-circle text-2xl text-calmRed"></i> : <i className="las la-plus-circle text-2xl text-calmRed"></i>
                        }
                    </span>
                </div>
                <hr className={`text-separatorGray ${program['week5']?.isOpen ? 'my-4' : 'mt-4'}`}/>
                {
                    program['week5']?.isOpen && (
                        <>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-23" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-23').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-23').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week5']?.items['2024-05-23'] && program['week5']?.items['2024-05-23'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week5']?.items['2024-05-23'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray mb-4"/>
                            <div className="flex items-start">
                                <div style={{minWidth: '72px'}}>
                                    <time dateTime="2024-05-25" className="text-calmRed sans text-base font-bold capitalize">{ moment('2024-05-25').format('MMMM D') }</time>
                                    <span className="block text-deepSea capitalize sans text-base">{ moment('2024-05-25').format('dddd') }</span>
                                </div>
                                <div className="pl-4">
                                    {
                                        program['week5']?.items['2024-05-25'] && program['week5']?.items['2024-05-25'].map((item, i) => {
                                            return (
                                                <div key={item.id}>
                                                    <div className="flex flex-col mb-4">
                                                        <Link to={`/posts/${item.id}`} className="text-calmRed sans text-base" dangerouslySetInnerHTML={createMarkup(item.title.rendered)}></Link>
                                                        <div>
                                                            <span className="text-deepSea sans text-base">{item.lokacija}</span> - <time className="text-deepSea sans text-base">{item.vrijeme }h</time>
                                                        </div>
                                                        {program['week5']?.items['2024-05-25'].length - 1 > i ? (<hr className="text-separatorGray mt-4"/>) : ''}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <hr className="text-separatorGray"/>
                        </>
                    )
                }
            </div>
            <div className="bg-deepSea py-3 mt-16">
                <div className="block w-full md:h-full md:justify-center">
                    <div className="flex flex-col items-center md:h-full md:justify-center md:flex-row items-center sans text-sm text-white md:my-0">
                        <span>© {(new Date().getFullYear())} Festival Vjetra sva prava zadržana.</span>
                        <div className="flex items-center">
                            <span className="ml-1">Made with</span>
                            <span className="flex">
                                <i className="paw-l las la-paw text-calmYellow text-xl"></i>
                                <i className="paw-r las la-paw text-calmYellow text-xl"></i>
                            </span>
                            <span className="block">by</span>
                            <a className="ml-1 mb-0 sans underline" href="https://happywolf.me">Happy Wolf</a>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramMobile;