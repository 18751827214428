import React, {useEffect, useState} from "react";
import axios from "axios";
import PostsListItem from "../posts/PostsListItem";
import './post.scss';
import { useSearchParams } from "react-router-dom";

const Events = () => {

    const [events, setEvents] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');

    if (!searchParams.get('page')) {
        searchParams.set('page', 1);
        setSearchParams(searchParams);
    }
    const [totalNumberOfPages, setTotalNumberOfPages] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')));

    useEffect(() => {
        fetchPosts();
    }, [currentPage])
    useEffect(() => {
    }, [events])
    const fetchPosts=async()=>{
        setIsLoading(true);
        const response = await axios(`https://api.wind-fest.me/wp-json/wp/v2/event_listing?_embed&per_page=10&page=${currentPage}&event_listing_category=${language === 'me' ? 39:38}`);
        setTotalNumberOfPages(Array.from(Array(parseInt(response.headers['x-wp-totalpages'])).keys(), (_, i) => i + 1));
        setIsLoading(false);
        setEvents(response.data);
    }

    function goToPreviousPage() {
        setCurrentPage((currentPage - 1));
        searchParams.set('page', currentPage - 1);
        setSearchParams(searchParams);
    }

    function goToNextPage() {
        setCurrentPage(currentPage + 1);
        searchParams.set('page', currentPage + 1);
        setSearchParams(searchParams);
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(parseInt(pageNumber));
        searchParams.set('page', pageNumber);
        setSearchParams(searchParams);
    }

    return (
        <div className="posts mt-10 md:mt-16 lg:mt-28">
            <div className="bg-azure hidden md:block text-center" style={{'width': '147px', position: 'fixed', height: '100vh', top: '0'}}>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
                <span className="marqueeStyle section__title mt-20 text-azure uppercase">{language === 'me' ? 'Događaji' : 'Events'}</span>
            </div>
            <div className="container mx-auto">
                {
                    isLoading && (
                        <div className="flex justify-center items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-deepSea" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    )
                }
                <div className="grid grid-cols-12 gap-6">
                    {
                        events.map((event, index) => {
                            return (
                                <div key={event.id} className={`col-start-2 col-span-10 ${index % 2 === 0 ? 'md:col-span-4 md:col-start-3':'md:col-span-4 md:col-start-8'}`}>
                                    <PostsListItem
                                        id={event.id} title={event.title.rendered} isEvent
                                        image={event.featured_media !== 0 ? event._embedded['wp:featuredmedia'][0].source_url: null}
                                        excerpt={event.excerpt ? event.excerpt.rendered : null} />
                                </div>
                            )
                        })
                    }
                </div>
                {
                    !isLoading && (
                        <div className="flex items-center justify-center pb-20 pt-10 text-deepSea">
                            <button onClick={() => goToPreviousPage()} disabled={currentPage === 1} className="flex items-center px-4 cursor-posans">
                                <i className="las la-long-arrow-alt-left text-2xl"></i>
                                <span className="flex items-center italic">prethodna</span>
                            </button>
                            {
                                totalNumberOfPages.map(page => {
                                    return <button className="px-4 text-darkSea cursor-posans" onClick={changePage} key={page}>{page}</button>
                                })
                            }
                            <button onClick={() => goToNextPage()} disabled={currentPage === totalNumberOfPages.length} className="flex items-center px-4 cursor-posans">
                                <span className="flex items-center italic">sljedeća</span>
                                <i className="las la-long-arrow-alt-right text-2xl"></i>
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default Events;