import React, { useState } from "react";
import {Link} from "react-router-dom";
import defaultImage from '../../assets/images/default-post-image-des.jpg';

const PostsListItem = (props) => {

    const {
        id,
        title,
        excerpt,
        post,
        image,
        isEvent
    } = props;

    const isMainNews = false;
    const isLastNews = false;

    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language'): 'me');

    const createMarkup = function (html) {
        return { __html: html };
    }

    return (
        <>
            <div className={isMainNews ? `flex`: 'mb-4 flex flex-col items-center md:block'}>
                <div className="homepage-news__image--small mx-auto">
                    <img className={`homepage-news__image homepage-news__image${isMainNews ? '--large' : '--small mb-3'}`} src={image ? image : defaultImage} alt="Image"/>
                    <div className={isMainNews ? `p-6`: ''} style={{'maxWidth': isMainNews ? '480px': '100%'}}>
                        <h3 className={`text-deepSea mb-2 sans ${isMainNews ? 'text-4xl': 'text-2xl'}`} dangerouslySetInnerHTML={createMarkup(title)}></h3>
                        { isMainNews && <p className="sans mb-2">Prvi FESTIVAL VJETRA dogodiće se od Uskrsa do Vaskrsa na više lokacija, a činiće ga mozaik manifestacija različitih oblasti kulture...</p> }
                        <Link to={isEvent ? `/events/${id}` :`/posts/${id}`} className="sans fw-300 inline-flex items-center" href="">
                            <span className="underline italic">{language === 'me' ? 'pročitaj više' : 'read more'}</span>
                            <i className="las la-long-arrow-alt-right text-2xl ml-2"></i>
                        </Link>
                    </div>
                    {
                        isLastNews && <a className="sans fw-300 inline-flex items-center mt-121" href="">
                            <span className="underline italic">{language === 'me' ? 'pogledaj sve vijesti' : 'check all news'}</span>
                            <i className="las la-long-arrow-alt-right text-2xl ml-2"></i>
                        </a>
                    }
                </div>
            </div>
        </>
    )
}

export default PostsListItem;