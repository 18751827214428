import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link, useSearchParams} from "react-router-dom";
import moment from "moment";
import 'moment/locale/sr';
import ProgramMobile from "../ProgramMobile/ProgramMobile";
import ProgramDesktop from "../ProgramDesktop/ProgramDesktop";

const Program = () => {

    const [language, setLanguage] = useState(localStorage.getItem('language') ? localStorage.getItem('language') : 'me');
    const [currentDate, setCurrentDate] = useState(moment());
    const [program, setProgram] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);

    const [categoriesUrl, setCategoriesUrl] = useState('');
    const [allEvents, setAllEvents] = useState(true);
    const [sport, setSport] = useState(searchParams.get('sport') === '1' ? true : false);
    const [culture, setCulture] = useState(searchParams.get('culture') === '1' ? true : false);
    const [fun, setFun] = useState(searchParams.get('fun') === '1' ? true : false);
    const [education, setEducation] = useState(searchParams.get('education') === '1' ? true : false);

    useEffect(() => {
        fetchPosts();
    }, [categoriesUrl]);

    useEffect(() => {
        generateURL();
    }, [sport,culture,fun,education])

    useEffect(() => {
        let categoriesUrl = '';
        if (allEvents) {
            setCategoriesUrl('&categories=43 44 45 46');
        } else {
            if (sport) {
                categoriesUrl += '43 ';
            }

            if (fun) {
                categoriesUrl += '45 ';
            }

            if (culture) {
                categoriesUrl += '44 ';
            }

            if (education) {
                categoriesUrl += '46';
            }
        }

        if (categoriesUrl !== '') {
            categoriesUrl = '&categories=' + categoriesUrl;
            setCategoriesUrl(categoriesUrl);
        }
    }, [])

    const generateURL = () => {

        searchParams.delete('sport');
        searchParams.delete('culture');
        searchParams.delete('fun');
        searchParams.delete('education');

        let url = '';

        if (sport) {
            url += '43 ';
        }

        if (fun) {
            url += '45 ';
        }

        if (culture) {
            url += '44 ';
        }

        if (education) {
            url += '46';
        }

        if (url.length === 11 || url.length === 0) {
            setAllEvents(true);
            setSport(false);
            setCulture(false);
            setFun(false);
            setEducation(false);
            url = '&categories=43 44 45 46';
        } else {
            setAllEvents(false);
            url = '&categories=' + url;
        }
        setCategoriesUrl(url);
    }

    useEffect(() => {
        if (allEvents) {
            setSport(false);
            setCulture(false);
            setFun(false);
            setEducation(false);
        }
    }, [allEvents])
    const fetchPosts=async()=>{
        setIsLoading(true);
        if (window.innerWidth > 768) {
            document.body.style.overflow = "hidden";
        }
        //42&categories=44&categories=43&categories=1&categories=45
        // const response = await axios(`https://api.wind-fest.me/wp-json/wp/v2/posts?_embed&categories=${language === 'me' ? 37 : 36},42,44,43,1,45`);
        const response = await axios(`https://api.wind-fest.me/wp-json/wp/v2/posts?posts?_embed&tags=${language === 'me' ? 47 : 48}${categoriesUrl}&per_page=100`);
        const data = response.data.filter((item) => {
            return item.categories.includes(language === 'me' ? 37 : 36) && item.categories.includes(49);
        })

        for (var i = 0; i < data.length; i++ ) {
            data[i].dateFormat = moment(data[i].datum + ' ' + data[i].vrijeme);
        }

        data.sort((a,b) => moment(a.dateFormat)- moment(b.dateFormat));

        let programI = data.reduce((accObj, currentObj) => {
            accObj[currentObj.datum] = accObj[currentObj.datum] || [];
            accObj[currentObj.datum].push(currentObj);
            return accObj;
        }, {});

        if (language === 'me') {
            moment.locale('sr');
        } else {
            moment.locale('en');
        }

        let weeks = {
            'week1' : {
                items: [],
                length: 0,
                key: '',
                isOpen: false
            },
            'week2' : {
                items: [],
                length: 0,
                key: '',
                isOpen: false
            },
            'week3' : {
                items: [],
                length: 0,
                key: '',
                isOpen: false
            },
            'week4' : {
                items: [],
                length: 0,
                key: '',
                isOpen: false
            },
            'week5' : {
                items: [],
                length: 0,
                key: '',
                isOpen: false
            }
        };

        const objectKeys = Object.keys(programI);
        for (let i = 0; i < objectKeys.length; i++) {
            switch(objectKeys[i]) {
                case '2024-04-26':
                    weeks['week1'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week1'].length = programI[objectKeys[i]].length > weeks['week1'].length ? programI[objectKeys[i]].length : weeks['week1'].length;
                    weeks['week1'].key = programI[objectKeys[i]].length >= weeks['week1'].length ? objectKeys[i] : weeks['week1'].key;
                    break;
                case '2024-04-27':
                    weeks['week1'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week1'].length = programI[objectKeys[i]].length > weeks['week1'].length ? programI[objectKeys[i]].length : weeks['week1'].length;
                    weeks['week1'].key = programI[objectKeys[i]].length >= weeks['week1'].length ? objectKeys[i] : weeks['week1'].key;
                    break;
                case '2024-04-28':
                    weeks['week1'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week1'].length = programI[objectKeys[i]].length > weeks['week1'].length ? programI[objectKeys[i]].length : weeks['week1'].length;
                    weeks['week1'].key = programI[objectKeys[i]].length >= weeks['week1'].length ? objectKeys[i] : weeks['week1'].key;
                    break;
                case '2024-05-03':
                    weeks['week2'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week2'].length = programI[objectKeys[i]].length > weeks['week2'].length ? programI[objectKeys[i]].length : weeks['week2'].length;
                    weeks['week2'].key = programI[objectKeys[i]].length >= weeks['week2'].length ? objectKeys[i] : weeks['week2'].key;
                    break;
                case '2024-05-10':
                    weeks['week3'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week3'].length = programI[objectKeys[i]].length > weeks['week3'].length ? programI[objectKeys[i]].length : weeks['week3'].length;
                    weeks['week3'].key = programI[objectKeys[i]].length >= weeks['week3'].length ? objectKeys[i] : weeks['week3'].key;
                    break;
                case '2024-05-11':
                    weeks['week3'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week3'].length = programI[objectKeys[i]].length > weeks['week3'].length ? programI[objectKeys[i]].length : weeks['week3'].length;
                    weeks['week3'].key = programI[objectKeys[i]].length >= weeks['week3'].length ? objectKeys[i] : weeks['week3'].key;
                    break;
                case '2024-05-12':
                    weeks['week3'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week3'].length = programI[objectKeys[i]].length > weeks['week3'].length ? programI[objectKeys[i]].length : weeks['week3'].length;
                    weeks['week3'].key = programI[objectKeys[i]].length >= weeks['week3'].length ? objectKeys[i] : weeks['week3'].key;
                    break;
                case '2024-05-17':
                    weeks['week4'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week4'].length = programI[objectKeys[i]].length > weeks['week4'].length ? programI[objectKeys[i]].length : weeks['week4'].length;
                    weeks['week4'].key = programI[objectKeys[i]].length >= weeks['week4'].length ? objectKeys[i] : weeks['week4'].key;
                    break;
                case '2024-05-18':
                    weeks['week4'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week4'].length = programI[objectKeys[i]].length > weeks['week4'].length ? programI[objectKeys[i]].length : weeks['week4'].length;
                    weeks['week4'].key = programI[objectKeys[i]].length >= weeks['week4'].length ? objectKeys[i] : weeks['week4'].key;
                    break;
                case '2024-05-19':
                    weeks['week4'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week4'].length = programI[objectKeys[i]].length > weeks['week4'].length ? programI[objectKeys[i]].length : weeks['week4'].length;
                    weeks['week4'].key = programI[objectKeys[i]].length >= weeks['week4'].length ? objectKeys[i] : weeks['week4'].key;
                    break;
                case '2024-05-23':
                    weeks['week5'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week5'].length = programI[objectKeys[i]].length > weeks['week5'].length ? programI[objectKeys[i]].length : weeks['week5'].length;
                    weeks['week5'].key = programI[objectKeys[i]].length >= weeks['week5'].length ? objectKeys[i] : weeks['week5'].key;
                    break;
                case '2024-05-25':
                    weeks['week5'].items[objectKeys[i]] = programI[objectKeys[i]];
                    weeks['week5'].length = programI[objectKeys[i]].length > weeks['week5'].length ? programI[objectKeys[i]].length : weeks['week5'].length;
                    weeks['week5'].key = programI[objectKeys[i]].length >= weeks['week5'].length ? objectKeys[i] : weeks['week5'].key;
                    break;
                default:
                    break;
            }
        }
        if(currentDate.isBefore('2024-05-29')) {
            weeks['week1'].isOpen = true;
        } else if(currentDate.isBefore('2024-05-04') && currentDate.isAfter('2024-04-28')) {
            weeks['week2'].isOpen = true;
        } if(currentDate.isBefore('2024-05-12') && currentDate.isAfter('2024-05-04')) {
            weeks['week3'].isOpen = true;
        } else if(currentDate.isBefore('2024-05-20') && currentDate.isAfter('2024-05-12')){
            weeks['week4'].isOpen = true;
        } else if(currentDate.isBefore('2024-05-25') && currentDate.isAfter('2024-05-20')) {
            weeks['week5'].isOpen = true;
        }
        console.log(weeks);
        setProgram(weeks);
        setIsLoading(false);
    }

    return window.innerWidth < 768 && !isLoading ?
        (
            <ProgramMobile program={program}
                           allEvents={allEvents}
                           sport={sport}
                           culture={culture}
                           fun={fun}
                           education={education}
                           setAllEvents={setAllEvents}
                           setSport={setSport}
                           setCulture={setCulture}
                           setFun={setFun}
                           setEducation={setEducation}
                           setProgram={setProgram}/>
        ): (
            <ProgramDesktop program={program}
                            allEvents={allEvents}
                            sport={sport}
                            culture={culture}
                            fun={fun}
                            education={education}
                            setAllEvents={setAllEvents}
                            setSport={setSport}
                            setCulture={setCulture}
                            setFun={setFun}
                            setEducation={setEducation}
                            setProgram={setProgram} />
        )
}

export default Program;